const ROUTES = {
    MERCHANT: {
        AUTH: {
            LOGIN: '/merchant/login',
            LOGOUT: '/merchant/logout'
        },
        RESTAURANTLOGS: {
            INDEX: '/merchant/restaurantlog',
            EXPORT: '/merchant/restaurantlogExport',
        },
        CONFIG: {
            CATEGORY: '/merchant/config/bycategory',
            PERMISSION: '/merchant/config/bypermission',
            ASSIGN: '/merchant/config/assignRestaurants',
            CACHE: '/merchant/config/clearCache',
            RESETCREDITS: {
                CREATE: '/merchant/config/resetCredits/create',
                STORE: '/merchant/config/resetCredits',
                EDIT: '/merchant/config/resetCredits/@/edit',
                UPDATE: '/merchant/config/resetCredits/@'
            },
            DEFAULTPLAYER: {
                INDEX: '/merchant/config/defaultPlayer',
                CREATE: '/merchant/config/defaultPlayer/create',
                STORE: '/merchant/config/defaultPlayer',
                EDIT: '/merchant/config/defaultPlayer/@/edit',
                UPDATE: '/merchant/config/defaultPlayer/@',
                DELETE: '/merchant/config/defaultPlayer/@',
            },
            TOURNAMENTSCONFIG: {
                INDEX: '/merchant/config/tournament',
                CREATE: '/merchant/config/tournament/create',
                STORE: '/merchant/config/tournament',
                EDIT: '/merchant/config/tournament/@/edit',
                UPDATE: '/merchant/config/tournament/@',
            }
        },
        USERS: {
            CREATE: '/merchant/config/user/create',
            STORE: '/merchant/config/user',
            EDIT: '/merchant/config/user/@/edit',
            UPDATE: '/merchant/config/user/@'
        },
        PERMISSIONS: {
            EDIT: '/merchant/permission/@/edit',
            UPDATE: '/merchant/permission/@'
        },
        SYSTEM: {
            INDEX: '/merchant/system',
            CREATE: '/merchant/system/create',
            STORE: '/merchant/system',
            EDIT: '/merchant/system/@/edit',
            UPDATE: '/merchant/system/@',
            STATE: '/merchant/system/changestate',
            PDF: '/merchant/system/pdfs',
            RFDID: '/merchant/system/@/rfid'
        },
        FORMS: {
            ACCREDITATION: {
                INDEX: '/merchant/mform/accreditation',
                CREATE: '/merchant/mform/accreditation/create',
                STORE: '/merchant/mform/accreditation',
                EDIT: '/merchant/mform/accreditation/@/edit',
                UPDATE: '/merchant/mform/accreditation/@',
                SHOW: '/merchant/mform/accreditation/@',
                STATE: '/merchant/mform/accreditation/changestate/@',
                EXPORT: '/merchant/mform/accreditation/export'
            },
            BALLBOY: {
                INDEX: '/merchant/mform/ballboy',
                CREATE: '/merchant/mform/ballboy/create',
                STORE: '/merchant/mform/ballboy',
                EDIT: '/merchant/mform/ballboy/@/edit',
                UPDATE: '/merchant/mform/ballboy/@',
                SHOW: '/merchant/mform/ballboy/@',
                STATE: '/merchant/mform/ballboy/changestate/@',
                EXPORT: '/merchant/mform/ballboy/export'
            },
            LINE: {
                INDEX: '/merchant/mform/line',
                CREATE: '/merchant/mform/line/create',
                STORE: '/merchant/mform/line',
                EDIT: '/merchant/mform/line/@/edit',
                UPDATE: '/merchant/mform/line/@',
                SHOW: '/merchant/mform/line/@',
                STATE: '/merchant/mform/line/changestate/@',
                EXPORT: '/merchant/mform/line/export'
            },
            VOLUNTEER: {
                INDEX: '/merchant/mform/volunteer',
                CREATE: '/merchant/mform/volunteer/create',
                STORE: '/merchant/mform/volunteer',
                EDIT: '/merchant/mform/volunteer/@/edit',
                UPDATE: '/merchant/mform/volunteer/@',
                SHOW: '/merchant/mform/volunteer/@',
                STATE: '/merchant/mform/volunteer/changestate/@',
                EXPORT: '/merchant/mform/volunteer/export'
            },
            PRESS: {
                INDEX: '/merchant/mform/press',
                CREATE: '/merchant/mform/press/create',
                STORE: '/merchant/mform/press',
                EDIT: '/merchant/mform/press/@/edit',
                UPDATE: '/merchant/mform/press/@',
                SHOW: '/merchant/mform/press/@',
                STATE: '/merchant/mform/press/changestate/@',
                EXPORT: '/merchant/mform/press/export'
            },
            PLAYER: {
                INDEX: '/merchant/mform/player',
                CREATE: '/merchant/mform/player/create',
                STORE: '/merchant/mform/player',
                EDIT: '/merchant/mform/player/@/edit',
                UPDATE: '/merchant/mform/player/@',
                SHOW: '/merchant/mform/player/@',
                STATE: '/merchant/mform/player/changestate/@',
                EXPORT: '/merchant/mform/player/export',
                ASSIGN: '/merchant/mform/player/assign'
            },
            GVOLUNTEER: {
                INDEX: '/merchant/mform/gvolunteer',
                CREATE: '/merchant/mform/gvolunteer/create',
                STORE: '/merchant/mform/gvolunteer',
                EDIT: '/merchant/mform/gvolunteer/@/edit',
                UPDATE: '/merchant/mform/gvolunteer/@',
                SHOW: '/merchant/mform/gvolunteer/@',
                STATE: '/merchant/mform/gvolunteer/changestate/@',
                EXPORT: '/merchant/mform/gvolunteer/export'
            },
            GACCREDITATION: {
                INDEX: '/merchant/mform/gaccreditation',
                CREATE: '/merchant/mform/gaccreditation/create',
                STORE: '/merchant/mform/gaccreditation',
                EDIT: '/merchant/mform/gaccreditation/@/edit',
                UPDATE: '/merchant/mform/gaccreditation/@',
                SHOW: '/merchant/mform/gaccreditation/@',
                STATE: '/merchant/mform/gaccreditation/changestate/@',
                EXPORT: '/merchant/mform/gaccreditation/export'
            },
            GPRESS: {
                INDEX: '/merchant/mform/gpress',
                CREATE: '/merchant/mform/gpress/create',
                STORE: '/merchant/mform/gpress',
                EDIT: '/merchant/mform/gpress/@/edit',
                UPDATE: '/merchant/mform/gpress/@',
                SHOW: '/merchant/mform/gpress/@',
                STATE: '/merchant/mform/gpress/changestate/@',
                EXPORT: '/merchant/mform/gpress/export'
            },
            GUEST: {
                INDEX: '/merchant/mform/guest',
                CREATE: '/merchant/mform/player/@/guest',
                STORE: '/merchant/mform/player/@/guest',
                EDIT: '/merchant/mform/player/@/edit',
                UPDATE: '/merchant/mform/player/@',
                SHOW: '/merchant/mform/player/@',
                STATE: '/merchant/mform/guest/changestate/@',
                EXPORT: '/merchant/mform/guest/export'
            },
        },
        RESTAURANT: {
            INDEX: '/merchant/restaurants/restaurant',
            VALIDATE: '/merchant/restaurants/restaurant',
            UPDATE: '/merchant/restaurants/restaurant/@'
        },
        SERVEFILE: '/merchant/preview',
        COLLECTIVE: {
            INDEX: '/merchant/signature',
            CREATE: '/merchant/signature/create',
            STORE: '/merchant/signature',
            EDIT: '/merchant/signature/@/edit',
            UPDATE: '/merchant/signature/@',
            SHOW: '/merchant/signature/@',
            VALIDATE: '/merchant/signature/validate/@',
            PRINT: '/merchant/signature/@/print'
        },
        IMPORT: {
            INDEX: '/merchant/import',
            CREATE: '/merchant/import/create',
            STORE: '/merchant/import',
            DOWNLOAD: '/merchant/import/download'
        }
    },
    PUBLIC: {
        BALLBOY: '/forms/recogepelotas',
        LINE: '/forms/lineas',
        PLAYER: '/forms/jugadores',
        ACCREDITATION: '/forms/@/acreditaciones',
        VOLUNTEER: '/forms/voluntarios',
        PRESS: '/forms/prensa',
        GOLFVOLUNTEER: '/golf/voluntarios',
        GOLFACCREDITATION: '/golf/@/acreditaciones',
        GOLFPRESS: '/golf/prensa',
        OLDPLAYER: '/forms/jugadores/old',
        OLDPLAYERSEARCH: '/forms/jugadores/old/search',
    },
    PRIVACY: {
        POLICY: '/privacy-policy',
        ACCREDITATION: '/privacy-accreditation'
    }
}

export default ROUTES;
